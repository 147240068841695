import styled from "styled-components";

export const Styles = styled.section`
  padding-top: 200px;
  .content {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    .data-list {
      cursor: pointer;
      img {
        width: 350px;
        margin: 0 20px;
      }
    }
  }

  @media only screen and (max-width: 1536px) {
    padding-top: 120px;
    .content {
      margin-top: 40px;
      gap: 16px;
      .data-list {
        img {
          width: 280px;
          margin: 0 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 100px;
    .content {
      margin-top: 40px;
      gap: 16px;
      .data-list {
        img {
          width: 240px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
    .content {
      gap: 12px;
      .data-list {
        img {
          width: 220px;
          margin: 0 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .content {
      gap: 8px;
      margin-top: 30px;

      .data-list {
        img {
          width: 170px;
          margin: 0 10px;
        }
      }
    }
  }
`;
