import React from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import { ReactComponent as IconArrow } from "assets/icons/arrow.svg";
import { Link } from "react-router-dom";

const ProblemSection = () => {
  return (
    <Styles id="problems">
      <div className="container">
        <div data-aos="fade-up">
          <TitleSection title="Problems" index="02" />
        </div>
        <div className="module">
          <div className="info" data-aos="fade-right" data-aos-delay="200">
            <div className="item">
              <span className="idx">01.</span>
              <div className="item-content">
                <p className="title">Lack of Awareness</p>
                <p className="desc">
                  Projects struggle with <span>low visibility</span> and{" "}
                  <span>awareness</span> within the market
                </p>
              </div>
            </div>
            <span className="hr" />
            <div className="item">
              <span className="idx">02.</span>
              <div className="item-content">
                <p className="title">Trust and Credibility</p>
                <p className="desc">
                  Challenging in building <span>trust</span> due to the
                  prevalence of scams and fraud
                </p>
              </div>
            </div>
            <span className="hr" />
            <div className="item">
              <span className="idx">03.</span>
              <div className="item-content">
                <p className="title">Competitive Landscape</p>
                <p className="desc">
                  Challenging for new projects to stand out
                </p>
              </div>
            </div>
          </div>
          <div className="action" data-aos="fade-left" data-aos-delay="200">
            <p className="label">
              We are HERE to address <span>YOUR PROBLEMS</span>
              <img src="/images/home/star_pro.png" alt="" />
            </p>
            <Link
              to={"https://typinglab.notion.site"}
              target="__blank"
            >
              <button className="btn">
                Go pitchdeck{" "}
                <span>
                  <IconArrow />
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ProblemSection;
