import React from "react";
import { Styles } from "./styles";
import TitleSection from "component/Title";
import { ReactComponent as IconX } from "assets/icons/x.svg";
import { ReactComponent as IconTele } from "assets/icons/telegram.svg";
import { ReactComponent as IconInfo1 } from "assets/icons/info1.svg";
import { ReactComponent as IconInfo2 } from "assets/icons/info2.svg";
import { ReactComponent as IconInfo3 } from "assets/icons/info3.svg";
import { ReactComponent as IconInfo4 } from "assets/icons/info4.svg";
import { Link } from "react-router-dom";

const OurTeamSection = () => {
  return (
    <Styles id="our-team">
      <div className="container">
        <div className="head">
          <div data-aos="fade-up">
            <TitleSection title="OUR TEAM" index="06" />
          </div>
          <p className="title-team" data-aos="fade-up" data-aos-delay="100">
            Our team
          </p>
        </div>
        <div className="content">
          {data?.map((el, idx) => {
            return (
              <div
                key={idx}
                className="item"
                data-aos="fade-up"
                data-aos-delay={100 * (idx + 1)}
              >
                <div className="item-head">
                  <div className="avt">
                    <img src={el.avt} alt="" />
                  </div>
                  <p className="name">{el.name}</p>
                </div>
                <div className="info">
                  <div className="info-item">
                    <IconInfo1 className="icon" />
                    <p> {el.info1}</p>
                  </div>
                  <div className="info-item">
                    <IconInfo2 className="icon" /> <p> {el.info2}</p>
                  </div>
                  <div className="info-item">
                    <IconInfo3 className="icon" /> <p>{el.info3}</p>
                  </div>
                  <div className="info-item">
                    <IconInfo4 className="icon" /> <p>{el.info4}</p>
                  </div>
                </div>
                <div className="social">
                  <div className="social">
                    <Link to={el.linkX} target="__blank">
                      <IconX />
                    </Link>
                    <Link to={el.linkTele} target="__blank">
                      <IconTele />
                    </Link>
                  </div>
                </div>
                <span className="founder">Founder</span>
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

export default OurTeamSection;

const data = [
  {
    avt: "/images/home/founder1.png",
    name: "Andrew",
    info1: (
      <>
        @0xAndrewmoh - <span>39.5k</span> followers
      </>
    ),
    info2: (
      <>
        <span>5</span> years in Defi space
      </>
    ),
    info3: (
      <>
        Currently being{" "}
        <span>BNB Chain KOL, APX Finance & Entangle Ambassador</span>
      </>
    ),
    info4: <>Supported 70+ global projects</>,
    linkX: "https://twitter.com/0xAndrewMoh",
    linkTele: "https://t.me/AndrewMoh",
  },
  {
    avt: "/images/home/founder2.png",
    name: "Kent Defi",
    info1: <>@KentDefi</>,
    info2: (
      <>
        <span>4</span> years in Defi space
      </>
    ),
    info3: (
      <>
        <span>5</span> years in Project Management
      </>
    ),
    info4: (
      <>
        Supported <span>40+</span> global projects
      </>
    ),
    linkX: "https://twitter.com/KentDefi",
    linkTele: "https://t.me/KentDefi",
  },
  {
    avt: "/images/home/founder3.jpg",
    name: "Otis",
    info1: <>@0xOtisoft</>,
    info2: (
      <>
        <span>7</span> years in blockchain space
      </>
    ),
    info3: (
      <>
        Currently being <span>KOLs</span> manager
      </>
    ),
    info4: (
      <>
        Supported <span>40+</span> global projects
      </>
    ),
    linkX: "https://x.com/0xOtisoft",
    linkTele: "",
  },
];
